import clsx from 'clsx'
import { GoogleFirebaseLogin } from 'lib/auth/google-login-card'
import { useEditorFirst } from 'lib/editor/use-editor-first'
import { observer } from 'mobx-react-lite'
import Image from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import { AuthCard } from '../lib/auth/auth-card'
import { LoginForm } from '../lib/auth/login'
import { FullSpinner } from '../lib/dashboard/full-spinner'
import { useStores } from '../lib/models/context'
import styles from './../lib/index.module.scss'

const IndexPage = () => {
    const { auth } = useStores()
    const [errorGoogleSignin, setErrorGoogleSignin] = useState<string | null>(
        null
    )

    useEditorFirst()

    const isLoading =
        !auth.syncedOnce || auth.isAuthenticated || !process.browser

    // Note we disable SSR for login to prevent flash of login page
    return (
        <div
            className={clsx(
                'dashboard min-h-screen bg-secondary-50',
                styles.container
            )}
        >
            <AuthCard>
                <div className="flex flex-col items-center my-3">
                    <div className="mb-6 mt-12">
                        <Image
                            src="/static/img/logo-text.svg"
                            alt="Jenni"
                            width="160"
                            height="48"
                        />
                    </div>
                    <h2 className="text-2xl">Glad you&apos;re back!</h2>
                </div>
                <div className="flex items-center justify-center my-3">
                    <GoogleFirebaseLogin
                        auth={auth.auth}
                        setErrorGoogleSignin={setErrorGoogleSignin}
                        text={'Log in with Google'}
                    />
                </div>
                <p className="text-center mt-3">Or</p>
                <p>Login to your account.</p>
                <LoginForm errorGoogleSignin={errorGoogleSignin} />
                <Link href="/forgot-password">
                    <a>Forgot Password?</a>
                </Link>
                <p className="my-2">
                    Don&apos;t have an account?{' '}
                    <Link href="/register">
                        <a data-cy="create-account-link">
                            Create a new account.
                        </a>
                    </Link>
                </p>
            </AuthCard>
            {isLoading && (
                <div className={styles.spinnerContainer}>
                    <FullSpinner />
                </div>
            )}
        </div>
    )
}

export default observer(IndexPage)
