import { FirebaseError } from '@firebase/util'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { Formik } from 'formik'
import Router from 'next/router'
import React from 'react'
import { Button, Group, TextInput, PasswordInput } from '@mantine/core'
import * as yup from 'yup'
import { SpinnerHorizontal } from '../common/spinner-horizontal'
import { useStores } from '../models/context'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'

const schema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Required!'),
    password: yup.string().min(6, 'Too Short!').required('Required!')
})

interface ILoginForm {
    errorGoogleSignin: string | null
}

export const LoginForm = ({ errorGoogleSignin }: ILoginForm) => {
    const { auth } = useStores()

    return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={async (data, actions) => {
                    actions.setSubmitting(true)

                    try {
                        if (auth.isAuthenticated) {
                            // User is already logged in!
                            actions.setSubmitting(false)
                            Router.push({
                                pathname: '/dashboard',
                                query: Router.query
                            })
                            return
                        }

                        //   Sign the user in, now that we have "logged in".
                        await signInWithEmailAndPassword(
                            auth.auth,
                            data.email,
                            data.password
                        )
                        // Else go to dashboard
                        Router.push({
                            pathname: '/dashboard',
                            query: Router.query
                        })
                    } catch (err) {
                        console.warn(err)

                        if (err instanceof FirebaseError) {
                            // (err as FirebaseError).code
                            actions.setFieldError(
                                'general',
                                'Invalid username or password.'
                            )
                        }
                    }
                    actions.setSubmitting(false)
                }}
                initialValues={{
                    email: '',
                    password: '',
                    general: ''
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isSubmitting,
                    errors
                }) => (
                    <form onSubmit={handleSubmit} className="mt-4 space-y-3">
                        <TextInput
                            type="email"
                            name="email"
                            label="Email"
                            placeholder="Email"
                            data-cy="email-input"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.email}
                        />
                        <PasswordInput
                            name="password"
                            label="Password"
                            placeholder="Password"
                            data-cy="password-input"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.password}
                            visibilityToggleIcon={({ reveal }) =>
                                reveal ? (
                                    <EyeSlashIcon className="w-4 h-4" />
                                ) : (
                                    <EyeIcon className="w-4 h-4" />
                                )
                            }
                        />

                        {isSubmitting ? (
                            <Group className="w-full justify-center items-center">
                                <SpinnerHorizontal />
                            </Group>
                        ) : (
                            <div className="py-1">
                                <Button
                                    type="submit"
                                    data-cy="login-button"
                                    className="px-4 w-full"
                                    disabled={isSubmitting}
                                >
                                    Login
                                </Button>
                            </div>
                        )}
                        {errors.general && (
                            <p className="text-red-500 my-2">
                                {errors.general}
                            </p>
                        )}
                    </form>
                )}
            </Formik>
            {errorGoogleSignin && (
                <p className="text-red-500">{errorGoogleSignin}</p>
            )}
        </>
    )
}
